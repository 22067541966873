import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/_XEVH4yNqLs">
    <SEO title="Connection - Searching..." />
  </Layout>
)

export default SermonPost
